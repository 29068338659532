import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ApiService } from '../../service/api.service';
import { Product } from 'src/app/model/product';
import { Router, ActivatedRoute } from '@angular/router';
import { Subcontent } from 'src/app/model/subcontent';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  productMenu: Product[] ;
  subcontentMenu: Subcontent[];
  product = new Product();
  columns = [
    { prop: 'product' },
    { name: 'subMemuTitle' },
    { name: 'type' },
    { name: 'status' }
    ];
  constructor(public restApi: ApiService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.getProductMenu();
    this.getContentMenu();
  }

  getProductMenu() {
    this.restApi.getList('products/status/Active').subscribe((res) => {
      console.log('Product menu : ', res);
      this.productMenu = res;
    });
  }

  getContentMenu() {
    this.restApi.getList('subcontents/status/Active').subscribe((res) => {
      console.log('sub menu : ', res);
      this.subcontentMenu = res;
    });
  }

  scrollToTop() {
    $('body,html').animate({
      scrollTop : 0                       // Scroll to top of body
   }, 500);
  }

  toggle(mainMenu: string, submenu: string) {
     if ($('#' + mainMenu).prop('checked')) {
        $('#' + mainMenu).prop('checked', false);
        if ($('#' + submenu).prop('checked')) {
          $('#' + submenu).prop('checked', false);
        }
      }
     this.scrollToTop();
   }

   showContent(product: Product) {
     console.log('Product : ', product);
     // tslint:disable-next-line:max-line-length
     this.router.navigate(['/products'], { queryParams: { id: product.uuid, banner: product.banner, bannerFooter: product.bannerFooter } }) ;
   }

   showSubContent(subContent: Subcontent) {
    console.log('Sub content : ', subContent);
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/content'], { queryParams: { id: subContent.uuid, banner: subContent.banner, bannerFooter: subContent.bannerFooter } }) ;
  }
}
