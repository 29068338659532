import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vts';

  constructor() {
    // tslint:disable-next-line:only-arrow-functions
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    console.log(e);
    if (e.key === 'F12') {
       alert('This function has been disabled');
       return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'J') {
      return false;
    }
    if (e.ctrlKey && e.key === 'U') {
      return false;
    }
    return true;
  }
}
