import { Injectable } from '@angular/core';

@Injectable()
export class Email {
    subject: string;
    message: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    company: string;
    position: string;
    industry: string;
}
