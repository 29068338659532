import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebRoutingModule } from './web-routing.module';
import { RequestQuoteComponent } from './request-quote/request-quote.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';

@NgModule({
  declarations: [RequestQuoteComponent],
  imports: [
    CommonModule,
    WebRoutingModule
  ]
})
export class WebModule { }
