import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Product } from 'src/app/model/product';
import { Router, ActivatedRoute } from '@angular/router';
import { Subcontent } from 'src/app/model/subcontent';
import { Email } from 'src/app/model/email';
import { Emailrest } from 'src/app/model/emailrest';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [Email]
})
export class FooterComponent implements OnInit {

  productMenu: Product[] ;
  subcontentMenu: Subcontent[];
  product = new Product();
  emailrest = new Emailrest();
  footerLocationList = [];
  footerDetails: {};
  columns = [
    { prop: 'product' },
    { name: 'subMemuTitle' },
    { name: 'type' },
    { name: 'status' }
    ];
  constructor(public restApi: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private email: Email) {
                this.getFooterDetails();
                this.getFooterLocations();
              }

  ngOnInit() {
    this.getProductMenu();
    this.getContentMenu();
  }

  getFooterLocations() {
    this.restApi.getList('footerlocations?search=status=Active').subscribe((res) => {
     console.log('footer location list : ' + res);
     this.footerLocationList = res;
    });
  }

  getFooterDetails() {
    this.restApi.getList('footerdetails').subscribe((res) => {
      if (res.length > 0 ) {
        this.footerDetails = res[0];
     }
    });
  }

  getProductMenu() {
    this.restApi.getList('products/status/Active').subscribe((res) => {
      console.log('Product menu : ', res);
      this.productMenu = res;
    });
  }

  getContentMenu() {
    this.restApi.getList('subcontents/status/Active').subscribe((res) => {
      console.log('sub menu : ', res);
      this.subcontentMenu = res;
    });
  }

   showContent(product: Product) {
     console.log('Product : ', product);
     // tslint:disable-next-line:max-line-length
     this.router.navigate(['/products'], { queryParams: { id: product.uuid, banner: product.banner, bannerFooter: product.bannerFooter } }) ;
     this.scrollToTop();
    }

   showSubContent(subContent: Subcontent) {
    console.log('Sub content : ', subContent);
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/content'], { queryParams: { id: subContent.uuid, banner: subContent.banner, bannerFooter: subContent.bannerFooter } }) ;
    this.scrollToTop();
  }

  scrollToTop() {
    $('body,html').animate({
      scrollTop : 0                       // Scroll to top of body
   }, 500);
  }
  emailRequest(type: string) {
    console.log('Type : ' + type );
    this.email.subject = type;
    $('#myModalFooter').show();
  }

  close() {
    $('#myModalFooter').hide();
  }

  sendEmail() {
    console.log('Email : ', this.email);
    this.emailrest.to = 'infovts20@gmail.com';
    this.emailrest.from = this.email.email;
    this.emailrest.subject = this.email.subject;
    this.emailrest.body = 'Name: ' + this.email.firstName + ' ' + this.email.lastName + '\n' +
    // tslint:disable-next-line:no-unused-expression
    'Email: ' + this.email.email + '\nPhone Number: ' + this.email.phoneNumber + '\n' +
    // tslint:disable-next-line:no-unused-expression
    'Company: ' + this.email.company + '\nPosition: ' + this.email.position + '\n' +
  // tslint:disable-next-line:no-unused-expression
    'Industry: ' + this.email.industry + '\n\n ' + this.email.message + '\n';
    this.restApi.saveData(this.emailrest, 'sendemail').subscribe((res) => {
      if (res === 'OK') {
        alert('Thank you! Your message has been successfully sent.');
        this.email = null;
      } else {
        alert('Sorry unable to send your message. Please try again later.');
      }
     // console.log('Send response : ', res);
    });
  }

}
