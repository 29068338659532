export class Product {
    uuid: string;
    subMenuTitle: string;
    contentTitle: string;
    content: string;
    type: string;
    banner: string;
    bannerFooter: string;
    status: string;

}
