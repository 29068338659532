import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.sass']
})
export class RequestDemoComponent implements OnInit {
  page: boolean;
  constructor() { }

  ngOnInit() {

    this.page = true;
    console.log(this.page);
  }

}
