import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  restUrl = 'https://api.vtsphil.com/vts/webcontent';
  restBrochureUrl = 'https://api.vtsphil.com';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders()
    .set('authorization', `Basic ` + btoa('admin:password') )
    .set('Content-Type', 'application/json')
   // headers: new HttpHeaders({
   //   'Content-Type': 'application/json',
   //   Authorization   : `Basic YWRtaW46cGFzc3dvcmQ=`
   // })
  };

  httpFileOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };

  constructor(private httpClient: HttpClient) { }

  getList(endpoint: string): Observable<any> {
    return this.httpClient.get('https://api.vtsphil.com/vts/webcontent/' + endpoint , this.httpOptions);
  }

  findOne(endpoint: string): Observable<any> {
    return this.httpClient.get('https://api.vtsphil.com/vts/webcontent/' + endpoint , this.httpOptions);
  }


  saveData(data: any, endpoint: string): Observable<any> {
    console.log('Api DATA : ', data);
    return this.httpClient.post('https://api.vtsphil.com/vts/webcontent/' + endpoint, JSON.stringify(data), this.httpOptions);
  }
  updateData(data: any, endpoint: string): Observable<any> {
    console.log('Api DATA : ', data);
    return this.httpClient.put('https://api.vtsphil.com/vts/webcontent/' + endpoint, JSON.stringify(data), this.httpOptions);
  }

  public postFile(fileToUpload: File, endpoint: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient.post('https://api.vtsphil.com/vts/webcontent/' + endpoint , formData);
  }
}
