import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WebComponent } from './web.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';


import { from } from 'rxjs';
import { RequestDemoComponent } from './request-demo/request-demo.component';
const routes: Routes = [
  {
    path : '', component : WebComponent,
    children : [
      {path : '', loadChildren : './content/content.module#ContentModule'}
    ]
  }
];

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations : [WebComponent, HeaderComponent, FooterComponent, RequestDemoComponent]
})
export class WebRoutingModule { }
